body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-title {
  flex-grow: 1;
}

.App-user-label {
  margin: 0 8px;
}

.Confession-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Confession-button {
  margin: 0 8px;
}
.Content-wrapper {
  width: 100%;
}

