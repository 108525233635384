.App {
  min-height: 100vh;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-title {
  flex-grow: 1;
}

.App-user-label {
  margin: 0 8px;
}
